import { Backdrop, Box, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { setMsgModal } from '../../../store/utilSlice'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import WifiTetheringErrorOutlinedIcon from '@mui/icons-material/WifiTetheringErrorOutlined'

export default function MsgModal({ iconType = '', title = '', content = '' }) {
  const dispatch = useDispatch()

  const renderIcon = () => {
    switch (iconType) {
      case 'SUCCESS':
        return <CheckCircleOutlinedIcon color='success' fontSize='large' sx={{fontSize: 50}}/>
      case 'ERROR':
        return <WifiTetheringErrorOutlinedIcon color='action' sx={{fontSize: 50}}/>
      default:
        return null
    }
  }

  const handleClose = () => {
    dispatch(
      setMsgModal({
        showModal: false,
        title: '',
        content: ''
      })
    )
  }

  return (
    <Backdrop sx={{ zIndex: 9, background: 'rgba(0, 0, 0, 0.26)' }} open={true} onClick={handleClose}>
      <Box sx={boxStyle}>
        {iconType && renderIcon()}
        {title && (
          <Typography id='transition-modal-title' variant='h6' component='h2'>
            {title}
          </Typography>
        )}

        <Typography id='transition-modal-description' sx={{ mt: 2 }}>
          {content}
        </Typography>
      </Box>
    </Backdrop>
  )
}

const boxStyle = {
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid gray',
  boxShadow: 24,
  px: 5,
  py: 4,
  borderRadius: 1,
  display:'flex',
  flexDirection:'column',
  alignItems: 'center'
}
