import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box, Link, Drawer, Button, Typography } from '@mui/material'
import useResponsive from '../../hooks/useResponsive'
import Logo from '../../assets/logo-white.png'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { useDispatch } from 'react-redux'
import { logout as storeLogout } from '../../store/authSlice'

const DashboardSidebar = ({ isOpenSidebar, onCloseSidebar }) => {
  const { pathname } = useLocation()

  const dispatch = useDispatch()
  const isMobile = useResponsive('down', 'md')

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar()
    }
  }, [pathname])

  const handleLogout = () => {
    dispatch(storeLogout())
  }

  const renderContent = (
    <BarContainer>
      <Link href='/'>
        <img src={Logo} alt='logo' style={{ maxWidth: '55px' }} />
      </Link>
      <Box >
        <Button onClick={handleLogout} variant='outlined' sx={{ minWidth: 30, width: 50, height: 50, borderRadius: 25, borderWidth: 0.8, borderColor:'white' }}>
          <LogoutOutlinedIcon sx={{ color:'white' ,fontSize: 28, transform: 'rotate(180deg)' }} />
        </Button>
        <Typography variant='body2' sx={{ color: 'text.white', mt: 1, textAlign:'center' }}>
          Logout
        </Typography>
      </Box>
    </BarContainer>
  )

  return (
    <RootStyle>
      {isMobile && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {!isMobile && (
        <Drawer
          open
          variant='persistent'
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'brand'
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  )
}

export default DashboardSidebar

const DRAWER_WIDTH = 80

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}))

const BarContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3, 0),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.brand.main
}))
