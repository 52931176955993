import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, InputAdornment, Typography } from '@mui/material'
import { FormProvider, RHFTextField } from '../common/form'
import { LoadingButton } from '@mui/lab'
import { useDispatch } from 'react-redux'
import { saveReCaptchaToken, saveCardInfo } from '../../store/authSlice'
import { toggleLoading, setMsgModal } from '../../store/utilSlice'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { formatExpiry, formatNumber } from '../../utils/formatNumber'
import { preCheck } from '../../api/index'

const LoginForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const RegisterSchema = Yup.object().shape({
    cardNum: Yup.string().required('Card number is required'),
    // cardNum: Yup.string().required('Card number is required').length(23, 'Invalid card number'),
    expDate: Yup.string()
      .required('Expiry date is required')
      .length(7, 'Invalid expiry date')
      .test('valid-expiry-date', '00? No joke :)', (v) => !v.startsWith('00')),
    securityCode: Yup.string().required('Security code is required').length(3, 'Invalid security code')
  })

  const defaultValues = {
    cardNum: '',
    expDate: '',
    securityCode: ''
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    getValues,
    setValue
  } = methods

  const handleCardNumChange = (e) => {
    if (e.nativeEvent.inputType === 'deleteContentBackward' && getValues().cardNum.length === 1) {
      setValue('cardNum', '')
      return
    }
    setValue(
      'cardNum',
      formatNumber(e.target.value)
        .match(/.{1,4}/g)
        .join(' ')
    )
  }

  const handleExpDateChange = (e) => {
    setValue('expDate', formatExpiry(e))
  }

  const handleSecurityCodeChange = (e) => {
    setValue('securityCode', formatNumber(e.target.value))
  }

  const onSubmit = () => {
    const setLoading = (loading) => dispatch(toggleLoading(loading))
    const setModal = (msgModalStates) => dispatch(setMsgModal(msgModalStates))

    setLoading(true)

    const payload = {
      ...getValues()
    }

    //登录成功后返回的数据，传给redux，跳转dashboard
    const successCallback = (cardInfo) => {
      dispatch(saveCardInfo(cardInfo))
      navigate('/dashboard')
    }

    preCheck(payload, successCallback, setLoading, setModal)
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          name='cardNum'
          label='Card Number'
          onChange={handleCardNumChange}
          inputProps={{
            maxLength: 23,
            style: bankNumFontStyle
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Typography variant='body2' sx={{ color: '#9b9ea3', fontStyle: 'italic' }}>
                  19 digits
                </Typography>
              </InputAdornment>
            )
          }}
        />
        <RHFTextField
          name='expDate'
          label='Expiry Date'
          onChange={handleExpDateChange}
          inputProps={{
            maxLength: 7,
            style: bankNumFontStyle
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Typography variant='body2' sx={{ color: '#9b9ea3', fontStyle: 'italic' }}>
                  mm / yy
                </Typography>
              </InputAdornment>
            )
          }}
        />
        <RHFTextField
          name='securityCode'
          label='Security Code'
          onChange={handleSecurityCodeChange}
          inputProps={{
            maxLength: 3,
            style: bankNumFontStyle
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Typography variant='body2' sx={{ color: '#9b9ea3', fontStyle: 'italic' }}>
                  3 digits
                </Typography>
              </InputAdornment>
            )
          }}
        />

        <LoadingButton fullWidth sx={{my:1}} size='large' color='brand' type='submit' variant='contained' loading={isSubmitting}>
          SIGN IN
        </LoadingButton>

        <GoogleReCaptcha onVerify={(token) => dispatch(saveReCaptchaToken(token))} />
      </Stack>
    </FormProvider>
  )
}

export default LoginForm

const bankNumFontStyle = {
  fontFamily: 'Farrington-7B-Qiqi',
  fontSize: 14,
  letterSpacing: 1.5
}
