import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Typography } from '@mui/material'
import useResponsive from '../hooks/useResponsive'
import DashboardSidebar from '../components/dashboard/DashboardSidebar.component'
import DashboardHeader from '../components/dashboard/DashboardHeader.component'
import RecentTransTable from '../components/dashboard/RecentTransTable.component'
import IdleTimer from '../components/common/IdleTimer.component'
import TimeOutModal from '../components/common/modal/TimeOutModal.component'
import DashboardSummaryTile from '../components/dashboard/DashboardSummaryTile.component'
import { useDispatch, useSelector } from 'react-redux'
import { saveAllNum } from '../store/authSlice'
import { toggleLoading, setMsgModal } from '../store/utilSlice'
import { inquiryBillList } from '../api'

const DashboardHome = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false)
  const [recentRecords, setRecentRecords] = useState([])

  const isMobile = useResponsive('down', 'md')
  const dispatch = useDispatch()
  const { cardNum, cardStatus } = useSelector((state) => state.auth.cardInfo)

  useEffect(() => {
    const getTransactions = () => {
      const setLoading = (loading) => dispatch(toggleLoading(loading))
      const setModal = (msgModalStates) => dispatch(setMsgModal(msgModalStates))

      setLoading(true)

      //请求到recordNum/recordList 存入redux
      const successCallback = (recordData) => {
        dispatch(saveAllNum(recordData.allRecordNum))
        setRecentRecords(recordData.recentRecords)
      }
      const requestData= {
        cardNum,
        pageNum: 1,
        pageSize: 999,
      }
      inquiryBillList(requestData, successCallback, setLoading, setModal)
    }
    getTransactions()
  }, [cardNum, dispatch])

  const getStatusBadge = () => {
    switch (cardStatus) {
      case 'N': //normal
        return (
          <BadgeContainer bgColor={'#FFFAEB'}>
            <Typography variant='body2' sx={{ color: '#B54708' }}>
              • Active
            </Typography>
          </BadgeContainer>
        )
      case 'U': //inactive
        return (
          <BadgeContainer bgColor={'#F2F4F7'}>
            <Typography variant='body2' sx={{ color: '#344054' }}>
              • Inactive
            </Typography>
          </BadgeContainer>
        )
      case 'D': //destroyed
        return (
          <BadgeContainer bgColor={'#F8F9FC'}>
            <Typography variant='body2' sx={{ color: '#363F72' }}>
              • Expired
            </Typography>
          </BadgeContainer>
        )
      case 'B': // block
        return (
          <BadgeContainer bgColor={'#FEF3F2'}>
            <Typography variant='body2' sx={{ color: '#B42318' }}>
              • Block
            </Typography>
          </BadgeContainer>
        )
      default:
        return null
    }
  }
  return (
    <RootStyle>
      {isMobile && <DashboardHeader onOpenSidebar={() => setSidebarOpen(true)} />}
      <DashboardSidebar isOpenSidebar={sidebarOpen} onCloseSidebar={() => setSidebarOpen(false)} />
      <MainStyle>
        <SummaryBox>
          <HeaderContainer>
            <Typography variant='h5' sx={{ my: 2 }}>
              Card Summary
            </Typography>
            {getStatusBadge()}
          </HeaderContainer>

          <Grid container spacing={5}>
            <Grid item xs={12} md={12} lg={4}>
              <DashboardSummaryTile tileType={'VIRTUAL_CARD'} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DashboardSummaryTile tileType={'BALANCE'} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DashboardSummaryTile tileType={'TRANS'} />
            </Grid>
          </Grid>
          <RecentTransTable recentRecords={recentRecords}/>
        </SummaryBox>
      </MainStyle>
      <IdleTimer handleOnIdle={() => setTimeoutModalOpen(true)} />
      <TimeOutModal open={timeoutModalOpen} handleClose={() => setTimeoutModalOpen(false)} />
    </RootStyle>
  )
}

export default DashboardHome

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
})

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  padding: theme.spacing(0, 3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(7, 3)
  }
}))

const SummaryBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

const HeaderContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}))

const BadgeContainer = styled('div')(({ theme, bgColor }) => ({
  backgroundColor: bgColor,
  marginLeft: theme.spacing(1.5),
  padding: theme.spacing(0.5, 1.5),
  borderRadius: 20
}))
