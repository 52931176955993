import { post } from './axios'
import { RSAEncryptCardInfo, signURL, getPublicData } from '../utils/apiUtils'

export const preCheck = (formData, successCallback, setLoading, setModal) => {
  const dateToArray = formData.expDate.split(' / ')
  const reverseDateArray = [dateToArray[1], dateToArray[0]].join('')
  const publicData = getPublicData('PRE_CHECK')
  const payload = {
    cardNumber: RSAEncryptCardInfo(formData.cardNum.replace(/\s/g, '')),
    expiryDate: RSAEncryptCardInfo(reverseDateArray),
    CVV2: RSAEncryptCardInfo(formData.securityCode),
    ...publicData
  }

  post(signURL('cardmgr/verifyCVV2?', payload), payload)
    .then(({ status, data }) => {
      setLoading(false)
      // console.log('verifyCVV2 res: ', data)
      if (status === 200 && data.code === 200 && data.data.reponseDesc === 'Success') {
        const storeData = {
          cardNum: formData.cardNum,
          expDate: formData.expDate,
          cardStatus: data.data.cardStatus,
          avaiBalance: data.data.avaiBalance,
          blockBalance: data.data.blockBalance
        }
        successCallback(storeData)
        return
      }
      setModal({
        showModal: true,
        title: 'Signin Error',
        content: data.msg === 'SUCCESS' ? data.data.reponseDesc : data.msg
      })
    })
    .catch((err) => {
      setLoading(false)
      setModal({
        showModal: true,
        iconType: 'ERROR',
        content: err.message || err
      })
    })
}

export const inquiryBillList = (requestData, successCallback, setLoading, setModal) => {
  const publicData = getPublicData('GET_TRANS_RECORD')

  const payload = {
    cardNumber: RSAEncryptCardInfo(requestData.cardNum.replace(/\s/g, '')),
    startDate: '20220101',
    endDate: publicData.tranDate,
    pageNum: requestData.pageNum,
    pageSize: requestData.pageSize,
    ...publicData
  }
  // console.log('inquiryBillList payload: ', payload)
  post(signURL('tranmgr/inquiryBillList?', payload), payload)
    .then(({ status, data }) => {
      setLoading(false)
      // console.log('inquiryBillList res: ', data)
      if (status === 200 && data.code === 200 && data.data.reponseDesc === 'Success') {
        const storeData = {
          allRecordNum: parseInt(data.data.recordNum),
          recentRecords: requestData.pageSize === 999 ? data.data.recordList.slice(0, 6) : data.data.recordList
        }
        successCallback(storeData)
        return
      }
      setModal({
        showModal: true,
        title: 'Fetch Error',
        content: data.msg === 'SUCCESS' ? data.data.reponseDesc : data.msg
      })
    })
    .catch((err) => {
      setLoading(false)
      setModal({
        showModal: true,
        iconType: 'ERROR',
        content: err.message || err
      })
    })
}
