import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export default function Loading() {
  return (
    <Backdrop sx={{ zIndex: 9, background: 'rgba(0, 0, 0, 0.26)' }} open={true}>
      <CircularProgress color='primary' />
    </Backdrop>
  )
}
