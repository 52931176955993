import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showLoading: false,
  msgModalStates: {
    showModal: false,
    iconType: '',
    title: '',
    content: ''
  }
}

export const utilSlice = createSlice({
  name: 'util',
  initialState,
  reducers: {
    toggleLoading: (state, action) => {
      state.showLoading = action.payload
    },
    setMsgModal: (state, action) => {
      state.msgModalStates = action.payload
    }
  }
})

export const { toggleLoading, setMsgModal } = utilSlice.actions

export default utilSlice.reducer
