// import numeral from 'numeral';

// export function fCurrency(number) {
//   return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
// }

// export function fPercent(number) {
//   return numeral(number / 100).format('0.0%');
// }

// export function fNumber(number) {
//   return numeral(number).format();
// }

// export function fShortenNumber(number) {
//   return replace(numeral(number).format('0.00a'), '.00', '');
// }

// export function fData(number) {
//   return numeral(number).format('0.0 b');
// }

export const formatNumber = (num) => {
  return (num.match(/\d+/g) || []).join('')
}
export const formatExpiry = (event) => {
  const eventData = event.nativeEvent && event.nativeEvent.data
  const prevExpiry = event.target.value.split(' / ').join('/')

  if (!prevExpiry) return null
  let expiry = prevExpiry

  if (/^[2-9]$/.test(expiry)) {
    expiry = `0${expiry}`
  }

  if (prevExpiry.length === 2 && +prevExpiry > 12) {
    const [head, ...tail] = prevExpiry
    expiry = `0${head}/${tail.join('')}`
  }

  if (/^1[/-]$/.test(expiry)) {
    return `01 / `
  }

  expiry = expiry.match(/(\d{1,2})/g) || []
  if (expiry.length === 1) {
    if (!eventData && prevExpiry.includes('/')) {
      return expiry[0]
    }
    if (/\d{2}/.test(expiry)) {
      return `${expiry[0]} / `
    }
  }
  if (expiry.length > 2) {
    const [, month, year] = expiry.join('').match(/^(\d{2}).*(\d{2})$/) || []
    return [month, year].join(' / ')
  }
  return expiry.join(' / ')
}
