import { useState } from 'react'
import { styled } from '@mui/material/styles'
import useResponsive from '../hooks/useResponsive'
import DashboardSidebar from '../components/dashboard/DashboardSidebar.component'
import DashboardHeader from '../components/dashboard/DashboardHeader.component'
import AllTransTable from '../components/dashboard/AllTransTable.component'
import IdleTimer from '../components/common/IdleTimer.component'
import TimeOutModal from '../components/common/modal/TimeOutModal.component'

const Transactions = () => {
  const [open, setOpen] = useState(false)
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false)
  const isMobile = useResponsive('down', 'md')
  return (
    <RootStyle>
      {
        isMobile && <DashboardHeader onOpenSidebar={() => setOpen(true)} />
      }
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <AllTransTable/>
      </MainStyle>
      <IdleTimer handleOnIdle={() => setTimeoutModalOpen(true)}/>
      <TimeOutModal open={timeoutModalOpen} handleClose={() => setTimeoutModalOpen(false)} />
    </RootStyle>
  )
}

export default Transactions

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
})

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  padding: theme.spacing(0, 3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(7, 3)
  }
}))
