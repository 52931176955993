import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loading from '../components/common/modal/Loading.component'
import MsgModal from '../components/common/modal/MsgModal.component'
import Login from '../pages/Login.page'
import Page404 from '../pages/Page404'
import Dashboard from '../pages/Dashboard.page'
import Transactions from '../pages/Transactions.page'

const ProtectedRoute = ({ children }) => {
  // const reCaptchaToken = useSelector((state) => state.auth.reCaptchaToken)
  const { cardNum } = useSelector((state) => state.auth.cardInfo)
  if (!cardNum) {
    return <Navigate to='/' replace />
  }
  return children
}
const AppRoutes = () => {
  const utilStates = useSelector((state) => state.util)
  const {
    showLoading,
    msgModalStates: { showModal, iconType, title, content }
  } = utilStates

  return (
    <>
      {showLoading && <Loading />}
      {showModal && (
        <MsgModal iconType={iconType} title={title} content={content} />
      )}
      <Routes>
        <Route index element={<Login />} />
        <Route
          path='/dashboard'
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path='/transactions'
          element={
            <ProtectedRoute>
              <Transactions />
            </ProtectedRoute>
          }
        />
        <Route path='/404' element={<Page404 />} />
        <Route path='/*' element={<Navigate to='/404' />} />
      </Routes>
    </>
  )
}
export default AppRoutes
