import { styled } from '@mui/material/styles'
import { Box, Typography, Grid, Divider } from '@mui/material'
import virtualCard from '../../assets/virtual-card.png'
import { useSelector } from 'react-redux'
import balanceIcon from '../../assets/balance-icon.png'
import walletIcon from '../../assets/wallet-icon.png'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { useNavigate } from 'react-router-dom'

const DashboardSummaryTile = ({ tileType }) => {
  const { cardNum, expDate, avaiBalance, blockBalance } = useSelector(
    (state) => state.auth.cardInfo
  )
  const allRecordNum = useSelector((state) => state.auth.allRecordNum)
  const dimensions = useWindowDimensions()
  const navigate = useNavigate()

  const getCardDescBottom = () => {
    return dimensions.width < 900 ? 0 : 4
  }
  const getCardDescLeft = () => {
    return dimensions.width < 900 ? 30 : 26
  }
  const last4Digits = (num) => {
    return num.slice(-5)
  }

  return (
    <SummaryGrid>
      {tileType === 'VIRTUAL_CARD' && (
        <CardContainer>
          <Grid
            container
            spacing={2}
            sx={{
              position: 'absolute',
              bottom: getCardDescBottom(),
              left: getCardDescLeft()
            }}
          >
            <Grid item xs={12}>
              <Typography
                variant='cardDesc'
                sx={{
                  fontSize: 24,
                  letterSpacing: 2,
                  position: 'relative',
                  top: 6
                }}
              >
                **** **** **** ***
              </Typography>
              <Typography variant='bankNum'>{last4Digits(cardNum)}</Typography>
            </Grid>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', ml: 2, mt: 2 }}
            >
              <Typography variant='cardDesc' sx={{ display: 'block' }}>
                VALID THRU
              </Typography>
              <Typography variant='bankNum' sx={{ fontSize: 12 }}>
                {expDate}
              </Typography>
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', ml: 4, mt: 2 }}
            >
              <Typography variant='cardDesc' sx={{ display: 'block' }}>
                CVV
              </Typography>
              <Typography
                variant='cardDesc'
                sx={{
                  fontSize: 24,
                  letterSpacing: 2,
                  position: 'relative',
                  bottom: 2
                }}
              >
                ***
              </Typography>
            </Box>
          </Grid>
        </CardContainer>
      )}
      {tileType === 'BALANCE' && (
        <SummaryContainer balanceNoWrap={true}>
          <img
            alt='balance-icon'
            src={balanceIcon}
            style={{ height: 52, width: 52 }}
          />
          <Typography variant='h5' sx={{ my: 2 }}>
            Balance
          </Typography>
          <BalanceRow>
            <div>
              <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                Available
              </Typography>
              <Typography variant='h4'>
                $ {parseFloat(avaiBalance).toFixed(2)}
              </Typography>
            </div>
            <Divider
              orientation='vertical'
              variant='middle'
              flexItem
              sx={{ mx: 3 }}
            />
            <div>
              <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                Pending
              </Typography>
              <Typography variant='h4'>
                $ {parseFloat(blockBalance).toFixed(2)}
              </Typography>
            </div>
          </BalanceRow>
        </SummaryContainer>
      )}
      {tileType === 'TRANS' && (
        <SummaryContainer>
          <img
            alt='wallet-icon'
            src={walletIcon}
            style={{ height: 52, width: 52 }}
          />
          <Typography variant='h5' sx={{ my: 2 }}>
            Transactions
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Total
          </Typography>
          <Typography
            variant='h4'
            onClick={() => navigate('/transactions')}
            sx={{ cursor: 'pointer' }}
          >
            {allRecordNum}
          </Typography>
        </SummaryContainer>
      )}
    </SummaryGrid>
  )
}

export default DashboardSummaryTile

const SummaryGrid = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start'
  }
}))

const CardContainer = styled('div')(({ theme }) => ({
  minWidth: 417,
  height: 244,
  backgroundColor: 'transparent',
  backgroundImage: `url(${virtualCard})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    backgroundSize: 'cover',
    height: 250
  }
}))

const SummaryContainer = styled('div')(({ theme, balanceNoWrap }) => ({
  width: 400,
  minWidth: balanceNoWrap ? 280 : 200,
  height: 244,
  borderRadius: 20,
  borderWidth: 1,
  borderColor: theme.palette.brand.main,
  borderStyle: 'dashed',
  backgroundColor: '#fff',
  padding: theme.spacing(2, 4),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}))

const BalanceRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start'
}))
