import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import OverseaTransPopover from './OverseaTransPopover.component'
import { formatDate, formatTime } from '../../utils/formatTime'
import { useNavigate } from 'react-router-dom'

const RecentTransTable = ({ recentRecords }) => {
  const navigate = useNavigate()

  return (
    <RootStyle>
      <TitleBox>
        <Typography variant='h5' sx={{ my: 2 }}>
          Transactions
        </Typography>

        <Typography variant='body2' onClick={() => navigate('/transactions')} sx={{ my: 2, cursor: 'pointer' }}>
          View all transactions
        </Typography>
      </TitleBox>

      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table sx={{ minWidth: 650 }} size='medium' aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Transaction No.</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Transaction Type</TableCell>
              <TableCell>Amount (AUD)</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recentRecords.map((row) => (
              <TableRow hover={true} key={row.tranId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component='th' scope='row'>
                  {row.tranId}
                </TableCell>
                <TableCell>{row.merchantName}</TableCell>
                <TableCell>{row.tranName.replace('CUP ','')}</TableCell>
                <TableCell>
                  $ {row.billAmt} {(row.tranAmt !== row.billAmt || row.feeAmt !== '0.00') && <OverseaTransPopover row={row} />}
                </TableCell>
                <TableCell>{formatDate(row.tranDate)}</TableCell>
                <TableCell>{formatTime(row.tranTime)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <div style={{backgroundColor:'red'}}>no data</div> */}
    </RootStyle>
  )
}

export default RecentTransTable

const RootStyle = styled('div')({
  width: '100%',
  marginTop: '1rem'
})

const TitleBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 0),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 0),
    justifyContent: 'space-around'
  }
}))
