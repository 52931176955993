import { alpha, styled } from '@mui/material/styles'
import { Box, AppBar, Toolbar, IconButton, Link } from '@mui/material'
import ListOutlinedIcon from '@mui/icons-material/ListOutlined'
import Logo from '../../assets/logo-white.png'

const DashboardHeader = ({ onOpenSidebar }) => {
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.white', display: { md: 'none' } }}>
          <ListOutlinedIcon />
        </IconButton>

        <Box>
          <Link href='/' sx={{}}>
            <img src={Logo} alt='logo' style={{ maxWidth: '60px' }} />
          </Link>
        </Box>

        <Box sx={{width: 40}}/> 
      </ToolbarStyle>
    </RootStyle>
  )
}

export default DashboardHeader

const APPBAR_MOBILE = 64

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  backgroundColor: theme.palette.brand.main,
  flexGrow: 1, 
  display:'flex', 
  justifyContent:'space-between' ,
}))
