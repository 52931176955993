import axios from 'axios'
import { ENV_KEY } from '../config/env.config'


export const post = (endpoint = '', data = {}) => {
  const instance = axios.create()
  instance.defaults.timeout = 20000

  const _url = ENV_KEY.getEnvKey().BASE_URL + '/' + endpoint
  // console.log('URL: ',_url)
  return instance.post(_url, data)
}