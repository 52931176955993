import NotFoundImg from '../assets/404.svg'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Button, Typography, Box } from '@mui/material'

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 520,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: theme.spacing(5, 3)
}))

const Page404 = () => {
  return (
    <ContentStyle>
      <Typography variant='h3' paragraph>
        Sorry, page not found!
      </Typography>
      <Typography sx={{ color: 'text.secondary' }}>
        Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.
      </Typography>
      <Box component='img' src={NotFoundImg} sx={styles.img} />
      <Button to={'/dashboard'}  color='brand' size='large' variant='contained' component={Link}>
        Go Back
      </Button>
    </ContentStyle>
  )
}

const styles = {
  img: { height: 240, mx: 'auto', my: { xs: 3, sm: 5 } }
}

export default Page404
