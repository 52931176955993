import Countdown from 'react-countdown'
import { Typography } from '@mui/material'

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return ''
  } else {
    return <Typography variant='p' sx={{ml:0.5}}> {'in  ' + seconds + 's'}</Typography>
  }
}

export default function CountdownTimer({ time, onComplete }) {
  return (
    <>
      <Countdown date={Date.now() + time} renderer={renderer} onComplete={onComplete}/>
    </>
  )
}
