import { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Link } from '@mui/material'
import useResponsive from '../hooks/useResponsive'
import Carousel from 'react-material-ui-carousel'
import LoginImg from '../assets/login.png'
import LoginImg2 from '../assets/login2.png'
import LoginImg3 from '../assets/login3.png'
import LoginImg4 from '../assets/login4.png'
import Logo from '../assets/logo.png'
import LoginForm from '../components/auth/LoginForm.component'
import useWindowDimensions from '../hooks/useWindowDimensions'

const Login = () => {
  const [bgIndex, setBgIndex] = useState(0)
  const mdUp = useResponsive('up', 'md')
  const windowHeight = useWindowDimensions().height

  return (
    <PageContainer>
      <ContentContainer>
        <Link href='https://www.unee.com.au'>
          <img src={Logo} alt='logo' style={{ maxWidth: '100px' }} />
        </Link>
        <Typography
          variant='h3'
          gutterBottom
          sx={{ color: 'text.secondary', mb: 3, mt: 2 }}
        >
          Gift Card
        </Typography>

        <Typography variant='body2' sx={{ color: 'text.secondary', mb: 5 }}>
          Start typing your Unee gift card details to login.
        </Typography>

        <LoginForm />

        {/* <Typography variant='body2' sx={{ color: 'text.secondary', mt: 2 }}>
          By logging in your Unee giftcard, you agree to the <Link href='/'>Terms and Conditions</Link>.
        </Typography> */}

        <LinkContainer>
          <Typography variant='body2' sx={{ m: 3 }}>
            <Link href='https://unee.com.au/contact/'>Contact</Link>
          </Typography>

          <Typography variant='body2' sx={{ m: 3 }}>
            <Link href='https://unee.com.au/faqs/ '>FAQ</Link>
          </Typography>

          <Typography variant='body2' sx={{ m: 3 }}>
            <Link href='https://unee.com.au/wp-content/uploads/2023/02/Gift-Card-Terms-and-Conditions.pdf'>
              T&C
            </Link>
          </Typography>
        </LinkContainer>
      </ContentContainer>

      {mdUp && (
        <BannerCard
          index={bgIndex}
          sx={{
            minWidth: windowHeight > 600 ? parseInt(windowHeight) * 0.72 : 400
          }}
        >
          <Carousel
            className='Example'
            autoPlay={true}
            animation={'slide'}
            indicators={false}
            timeout={600}
            cycleNavigation={true}
            navButtonsAlwaysVisible={false}
            navButtonsAlwaysInvisible={false}
            onChange={(now, previous) => setBgIndex(now)}
          >
            <Img alt='login' src={LoginImg} height={windowHeight} />
            <Img alt='login' src={LoginImg3} height={windowHeight} />
            <Img alt='login2' src={LoginImg2} height={windowHeight} />
            <Img alt='login' src={LoginImg4} height={windowHeight} />
          </Carousel>
        </BannerCard>
      )}
    </PageContainer>
  )
}

const Img = styled('img')(({ height }) => ({
  width: 'auto',
  minHeight: '580px',
  height
}))

const PageContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const BannerCard = styled('div')(() => ({
  backgroundColor: 'transparent',
  borderRadius: 0,
  borderWidth: 0,
  boxShadow: 'none',
  zIndex: -5
}))

const ContentContainer = styled('div')(({ theme }) => ({
  minWidth: 400,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(6, 3),
  [theme.breakpoints.down('md')]: {
    maxWidth: 400
  }
}))

const LinkContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
}))

export default Login
