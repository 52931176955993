// import { useState, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { ENV_KEY } from '../../config/env.config'

const TIMEOUT = ENV_KEY.getEnvKey().IDLE_TIMEOUT

const IdleTimer = (props) => {
  const handleOnIdle = props.handleOnIdle

  const { getRemainingTime } = useIdleTimer({
    timeout: TIMEOUT,
    onIdle: handleOnIdle
  })

  // const [remaining, setRemaining] = useState(TIMEOUT)
  // useEffect(() => {
  //   setRemaining(getRemainingTime())

  //   setInterval(() => {
  //     setRemaining(getRemainingTime())
  //   }, 300)
  // }, [getRemainingTime])

  return null
  // return <p>{remaining}</p>
}

export default IdleTimer
