import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import ThemeProvider from './theme'
import AppRoutes from './routes/AppRoutes'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import CircularProgress from '@mui/material/CircularProgress'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const root = createRoot(document.getElementById('root'))
const persistor = persistStore(store)

root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey='6Ld4LmsiAAAAACCQ2HusdGl2ROqy_5j5AUeKDFMj' language='en-AU'>
      <Provider store={store}>
        <PersistGate loading={<CircularProgress color='secondary' />} persistor={persistor}>
          <ThemeProvider>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
