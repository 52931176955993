import { forwardRef } from 'react'
import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useDispatch } from 'react-redux'
import { logout as storeLogout } from '../../../store/authSlice'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import CountdownTimer from '../../common/CountdownTimer.component'
import { ENV_KEY } from '../../../config/env.config'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const TIME = ENV_KEY.getEnvKey().LOGOUT_COUNT_DOWN

export default function TimeOutModal({ open, handleClose }) {
  const dispatch = useDispatch()

  const handleLogout = () => {
    handleClose()
    dispatch(storeLogout())
  }
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
        BackdropComponent={myBackdrop}
      >
        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <SentimentVerySatisfiedIcon color='action' sx={{ fontSize: 50, mr: 2 }} />
          Session timeout
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            For safety reasons your online session is about to be timed out. Please click Continue if you are still working.
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description' sx={{color:'red'}}>
            Automatic logout
            {open && <CountdownTimer time={TIME} onComplete={handleLogout} />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' variant='text' onClick={handleLogout}>
            Logout
          </Button>
          <Button variant='contained' color='brand' onClick={handleClose}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const myBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: 9,
  background: 'rgba(0, 0, 0, 0.26)'
}))
