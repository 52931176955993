const env = process.env.REACT_APP_ENV || 'dev'

const PROD_KEY = {
  BASE_URL: '/web-gateway/api-giftcard',
  RSA_ENCRYPT_PUBLIC_KEY_CARD_INFO:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAk1ywkOk3IlgNE3KqUYoC41spTBW0PRIBV43wIv/x80vd+MCqG1aXWnuhYY2Z3k8NUk8FJQqQPHcuxnfTyIDq4I0aJjyanuZs7dk9KQ9edl4lNghBHwrVRLwxO/ujn+xsle6u7fI5R/+mzk/IlbXL1dzi2bTBkVBeuN+vHr36Tb1W4pVGSDlgEwy8YnWMiiFQ++tGggy+3PcGxazNPXDhvx6H6bsd+GUX8/RMpp3rEZFKs53GkMB9Et/bxcR9vEJoOA2LinznM16HE1OwtnRXJ+fW0WbFmU9RUQAMRHC5Xb0p89XuSvcKVQ82p5s4SekNKviSRv3vHygkFzhlWL5eswIDAQAB',
  API_SIGN_KEY: 'BE6351E0B989F060CA992D13544D95AF941D7B673F26E2FB28774DFD931EDE37',
  IDLE_TIMEOUT: 300000, //5 mins
  LOGOUT_COUNT_DOWN: 60000 // 1 min
}

const DEV_KEY = {
  // BASE_URL: 'https://capi-internal-dev.unee.com.au/web-gateway/api-giftcard',
  BASE_URL: '/web-gateway/api-giftcard',
  RSA_ENCRYPT_PUBLIC_KEY_CARD_INFO:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApfA4odhYoUHCO2YaZv+OnyOfKiUVG9GjSQuAZvcs1H/TBzbWABq+cEjIMC+p5xfTOHC0rFy623nLWqukHH+r5xiRZ/I1cwJICY50E3WytsYlxCyWmDsa3HS6K0i77jL3B59DqvTHHLv2UXpQ+NuNrkuiDypcIEpaEevDjpSk+fy5Lc0mkpBsrHzpih9BC/6a71OW7Gn5NOWQt1wo4Zmf9Tz8mPX1GEytncr0eZ09YMRDTEmeN4wgdKrV4Pq9EXoT2TkA+iOSwqQ1X6AITresEP7Ij92zMiFVhOM95QiegdEQV7rHS+yFKpVhtLYZ7rtgXMCW9UkstgzbU5joKJYpGwIDAQAB',
  API_SIGN_KEY: '04685EAC2F15506A6BE5C28C9D581A9DAB2223D4AC62088722BD98754FBF339C',
  IDLE_TIMEOUT: 300000, //5 mins
  LOGOUT_COUNT_DOWN: 60000 // 1 min
}

const TEST_KEY = {
  BASE_URL: '/web-gateway/api-giftcard',
  RSA_ENCRYPT_PUBLIC_KEY_CARD_INFO:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApfA4odhYoUHCO2YaZv+OnyOfKiUVG9GjSQuAZvcs1H/TBzbWABq+cEjIMC+p5xfTOHC0rFy623nLWqukHH+r5xiRZ/I1cwJICY50E3WytsYlxCyWmDsa3HS6K0i77jL3B59DqvTHHLv2UXpQ+NuNrkuiDypcIEpaEevDjpSk+fy5Lc0mkpBsrHzpih9BC/6a71OW7Gn5NOWQt1wo4Zmf9Tz8mPX1GEytncr0eZ09YMRDTEmeN4wgdKrV4Pq9EXoT2TkA+iOSwqQ1X6AITresEP7Ij92zMiFVhOM95QiegdEQV7rHS+yFKpVhtLYZ7rtgXMCW9UkstgzbU5joKJYpGwIDAQAB',
  API_SIGN_KEY: '04685EAC2F15506A6BE5C28C9D581A9DAB2223D4AC62088722BD98754FBF339C',
  IDLE_TIMEOUT: 300000, //5 mins
  LOGOUT_COUNT_DOWN: 60000 // 1 min
}

export const ENV_KEY = {
  getEnvKey: () => {
    let key = PROD_KEY
    switch (env) {
      case 'prod':
        key = PROD_KEY
        break
      case 'test':
        key = TEST_KEY
        break
      case 'dev':
        key = DEV_KEY
        break
      default:
        key = PROD_KEY
    }
    return key
  }
}
