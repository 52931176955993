import { v4 as uuidV4 } from 'uuid'
import { ENV_KEY } from '../config/env.config'
import JSEncrypt from 'jsencrypt'
import CryptoJS from 'crypto-js'

// RSA加密
export const RSAEncryptCardInfo = (text) => {
  const publicKey = ENV_KEY.getEnvKey().RSA_ENCRYPT_PUBLIC_KEY_CARD_INFO
  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(publicKey)
  return encrypt.encrypt(text)
}
// URL加签名
export const signURL = (serviceUrl, payload) => {
  const timestamp = Date.now()
  let params = { ...payload, timestamp }

  let _params = Array.from(Object.keys(params), (key) => {
    return {
      key,
      value: params[key]
    }
  })

  _params.sort(function (x, y) {
    let a = x.key,
      b = y.key
    return a === b ? 0 : a > b ? 1 : -1
  })

  let signatureData = ''
  _params.map((item) => {
    const { key, value } = item
    if (key !== 'signature' && key && key !== null && key !== undefined && value && value !== null && value !== undefined) {
      signatureData += key + '=' + value + '&'
    }
    return true
  })
  
  signatureData = signatureData.substring(0, signatureData.length - 1)
  const apiSecret = ENV_KEY.getEnvKey().API_SIGN_KEY
  const signature = CryptoJS.HmacSHA256(signatureData, apiSecret).toString().toUpperCase()

  return serviceUrl + `timestamp=${timestamp}&&signature=${signature}`
}

export const getPublicData = (service) => {
  const tzoffset = (new Date()).getTimezoneOffset() * 60000
  return {
    participantId: 'UneeWeb',
    tranChnl: '07',
    tranDate: new Date(Date.now() - tzoffset).toISOString().slice(0, 10).replaceAll('-', ''), //'20221013'
    tranTime: new Date().toLocaleTimeString('en-GB').replaceAll(':', ''), //'102030'
    tranId: service + uuidV4()
  }
}
