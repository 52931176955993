export function formatDate(str) {
  if (!/^(\d){8}$/.test(str)) return 'invalid date'
  const y = str.substr(0, 4)
  const m = str.substr(4, 2)
  const d = str.substr(6, 2)
  return y + '/' + m + '/' + d
}

export function formatTime(str) {
  if (!/^(\d){6}$/.test(str)) return 'invalid date'
  const h = str.substr(0, 2)
  const m = str.substr(2, 2)
  const s = str.substr(4, 2)
  return h + ':' + m + ':' + s
}
