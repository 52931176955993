import { useState } from 'react'
import { Typography, Button, Popover } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CC from 'currency-codes'

const OverseaTransPopover = ({ row: { tranAmt, tranCurr, feeAmt } }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover123' : undefined

  const getCurrency = (codeStr) => {
    const currencyObj = CC.number(codeStr)
    return currencyObj.code
  }

  return (
    <>
      <Button onClick={handleClick} variant='text' sx={{ ml: 0.7, minWidth: 24, width: 24, height: 24, borderRadius: 12 }}>
        <InfoOutlinedIcon sx={{ color: 'text.secondary', fontSize: 19 }} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        PaperProps={{
          sx: { backgroundColor: 'rgba(16, 26, 58, 0.9)' }
        }}
      >
        <Typography variant='body2' sx={{ color: 'text.white', p: 1.5 }}>
          Overseas Transaction
          <br />
          Original order currency: {getCurrency(tranCurr)}
          <br />
          Original order amount: {tranAmt}
          {/* TODO!!!! */}
          {feeAmt !== '0.00' && (
            <>
              <br />
              Fee: {feeAmt} AUD 
            </>
          )}
        </Typography>
      </Popover>
    </>
  )
}

export default OverseaTransPopover
