import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Link } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { useSelector, useDispatch } from 'react-redux'
import { toggleLoading, setMsgModal } from '../../store/utilSlice'
import { inquiryBillList } from '../../api'
import OverseaTransPopover from './OverseaTransPopover.component'
import { formatDate, formatTime } from '../../utils/formatTime'
 
const AllTransTable = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [displayRecordList, setDisplayRecordList] = useState([])

  const recordNum = useSelector((state) => state.auth.allRecordNum)
  const { cardNum } = useSelector((state) => state.auth.cardInfo)
  const dispatch = useDispatch()

  useEffect(() => {
    const getTransactions = () => {
      const setLoading = (loading) => dispatch(toggleLoading(loading))
      const setModal = (msgModalStates) => dispatch(setMsgModal(msgModalStates))

      setLoading(true)

      //请求到recordNum/recordList 存入redux
      const successCallback = (recordData) => {
       setDisplayRecordList(recordData.recentRecords)
      }
      const requestData = {
        cardNum,
        pageNum: parseInt(page) + 1,
        pageSize: rowsPerPage
      }
      inquiryBillList(requestData, successCallback, setLoading, setModal)
    }
    getTransactions()
  }, [rowsPerPage, page, cardNum, dispatch, recordNum])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <RootStyle>
      <TitleBox>
        <Link href='/dashboard'>
          <IconButton aria-label='back' size='small'>
            <ArrowBackRoundedIcon fontSize='medium' color='black' />
          </IconButton>
        </Link>
        <Typography variant='h5' sx={{ ml: 1 }}>
          Transactions
        </Typography>
      </TitleBox>
      <Paper sx={{ width: '100%', height: '100%' }}>
        <TableContainer sx={{ mb: 4, maxHeight: '30%' }}>
          <Table sx={{ minWidth: 650 }} size='medium' aria-label='sticky table'>
            <TableHead>
              <TableRow>
                <TableCell>Transaction No.</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Transaction Type</TableCell>
                <TableCell>Amount (AUD)</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayRecordList.map((row) => (
                <TableRow hover={true} key={row.tranId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    {row.tranId}
                  </TableCell>
                  <TableCell>{row.merchantName}</TableCell>
                  <TableCell>{row.tranName.replace('CUP ','')}</TableCell>
                  <TableCell>
                    $ {row.billAmt} {row.tranAmt !== row.billAmt && <OverseaTransPopover row={row} />}
                  </TableCell>
                  <TableCell>{formatDate(row.tranDate)}</TableCell>
                  <TableCell>{formatTime(row.tranTime)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component='div'
          count={recordNum}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </RootStyle>
  )
}

export default AllTransTable

const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0, 0, 10, 0)
}))

const TitleBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(2, 0),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 0),
    justifyContent: 'space-around'
  }
}))
