import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  reCaptchaToken: '',
  cardInfo: {
    cardNum: ''
  },
  allRecordNum: 0,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveReCaptchaToken: (state, action) => {
      state.reCaptchaToken = action.payload
    },
    saveCardInfo: (state, action) => {
      state.cardInfo = action.payload
    },
    saveAllNum: (state, action) => {
      state.allRecordNum = action.payload
    },
    logout: (state) => {
      state.reCaptchaToken = ''
      state.cardInfo = {}
      state.allRecordNum = 0
      state.displayRecordList = []
      // window.location.reload()
    }
  }
})

export const { saveCardInfo, saveAllNum, saveReCaptchaToken, logout } = authSlice.actions

export default authSlice.reducer
